import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-city-selection',
  templateUrl: './city-selection.component.html',
  styleUrls: ['./city-selection.component.scss']
})
export class CitySelectionComponent {
  selectedCity: any; // Variable to hold the currently selected city
  searchQuery: string = '';
  popularCities = [
    { name: 'Delhi NCR', image: 'path/to/delhi-ncr-image.jpg' },
    { name: 'Bangalore', image: 'path/to/bangalore-image.jpg' },
    { name: 'Chennai', image: 'path/to/delhi-ncr-image.jpg' },
    { name: 'Mumbai', image: 'path/to/bangalore-image.jpg' },
    { name: 'Pune', image: 'path/to/delhi-ncr-image.jpg' },
    { name: 'Andra', image: 'path/to/bangalore-image.jpg' },
    { name: 'Kolkata', image: 'path/to/delhi-ncr-image.jpg' },
    { name: 'Lucknow', image: 'path/to/bangalore-image.jpg' },
    { name: 'Noida', image: 'path/to/delhi-ncr-image.jpg' },
    { name: 'Jaipur', image: 'path/to/bangalore-image.jpg' },
    // Add more popular cities here
  ];
  moreCities = [
    { name: 'Chandigarh', image: 'path/to/delhi-ncr-image.jpg' },
    { name: 'Coimbatore', image: 'path/to/bangalore-image.jpg' },
    { name: 'Faridabad', image: 'path/to/delhi-ncr-image.jpg' },
    { name: 'Ghaziabad', image: 'path/to/bangalore-image.jpg' },
    { name: 'Indore', image: 'path/to/delhi-ncr-image.jpg' },
    { name: 'Kochi', image: 'path/to/delhi-ncr-image.jpg' },
  ];
  
  filterCities() {
    const query = this.searchQuery.toLowerCase().trim();

    if (query) {
      // Filter popularCities based on city name
      this.popularCities = this.popularCities.filter(city =>
        city.name.toLowerCase().includes(query)
      );

      // Filter moreCities based on city name
      this.moreCities = this.moreCities.filter(city =>
        city.name.toLowerCase().includes(query)
      );
    } else {
      // If search query is empty, reset city lists to original
      this.resetCities();
    }
    }
    resetCities() {
      // Reset popularCities and moreCities arrays to original values
      this.popularCities = [
        { name: 'Delhi NCR', image: 'path/to/delhi-ncr-image.jpg' },
        { name: 'Bangalore', image: 'path/to/bangalore-image.jpg' },
        { name: 'Chennai', image: 'path/to/delhi-ncr-image.jpg' },
        { name: 'Mumbai', image: 'path/to/bangalore-image.jpg' },
        { name: 'Pune', image: 'path/to/delhi-ncr-image.jpg' },
        { name: 'Andra', image: 'path/to/bangalore-image.jpg' },
        { name: 'Kolkata', image: 'path/to/delhi-ncr-image.jpg' },
        { name: 'Lucknow', image: 'path/to/bangalore-image.jpg' },
        { name: 'Noida', image: 'path/to/delhi-ncr-image.jpg' },
        { name: 'Jaipur', image: 'path/to/bangalore-image.jpg' },
      ];
  
      this.moreCities = [
        { name: 'Chandigarh', image: 'path/to/delhi-ncr-image.jpg' },
        { name: 'Coimbatore', image: 'path/to/bangalore-image.jpg' },
        { name: 'Faridabad', image: 'path/to/delhi-ncr-image.jpg' },
        { name: 'Ghaziabad', image: 'path/to/bangalore-image.jpg' },
        { name: 'Indore', image: 'path/to/delhi-ncr-image.jpg' },
        { name: 'Kochi', image: 'path/to/delhi-ncr-image.jpg' },
      ];
    }
  
  

  // Method to select a city when clicked from the UI
  selectCity(city: any) {
     this.selectedCity = city;
    this.dialogRef.close(city);// Update selectedCity with the clicked city object
  
  }


  constructor(private dialogRef: MatDialogRef<CitySelectionComponent>) { }

  closeDialog() {
    this.dialogRef.close();
  }

}


