<div class="city-selection-dialog">
    <div class="dialog-header">
      <h2>Select a city</h2>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="search-bar">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Search for a city</mat-label>
        <input matInput [(ngModel)]="searchQuery" (input)="filterCities()">
        <button mat-icon-button matSuffix>
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="popular-cities">
      <h3>Popular Cities</h3>
      <div class="city-grid">
        <div class="city-item popular" *ngFor="let city of popularCities" (click)="selectCity(city)">
          <img [src]="city.image" alt="{{ city.name }}">
          <span>{{ city.name }}</span>
        </div>
      </div>
    </div>
    <div class="more-cities">
      <h3>More Cities</h3>
      <div class="city-grid">
        <div class="city-item more" *ngFor="let city of moreCities" (click)="selectCity(city)">
          <span>{{ city.name }}</span>
        </div>
      </div>
    </div>
  </div>
  